<template>
  <MasterDetail
    :actionBarButtons="actionBarButtons"
    :cols="cols"
    :customResource="customResource"
    disablePagination
    :opts="opts"
    has-year-filter
    :canDelete="false"
    :canEdit="false"
    :hasExportCSV="false"
    :hasNewButton="false"
  >
    <FormModal
      :cols="fields"
      :errorMessage="createModalError"
      :opened.sync="createModalOpened"
      title="Cadastro LALUR"
      :value.sync="createModalData"
    />
  </MasterDetail>
</template>

<script>
import FormModal from "@/components/form-modal.vue";
import MasterDetail from "@/components/master-detail.vue";
import moment from "moment";
import { toInteger } from "lodash";

export default {
  components: {
    FormModal,
    MasterDetail
  },
  computed: {
    actionBarButtons() {
      return [
        {
          text: "Adicionar",
          icon: "mdi-plus-box-outline",
          action: () => {
            this.createModalData = {};
            this.createModalOpened = true;
          }
        }
      ];
    },
    cols() {
      return this.periods.reduce(
        (acc, key) => [
          ...acc,
          {
            key,
            name: this.isQuarter
              ? key.replace("tri", " TRI")
              : moment(`${key}-01`).format("MM/yyyy"),
            type: this.$fieldTypes.MONEY
          }
        ],
        [{ key: "label", name: "" }]
      );
    },
    // TODO dados mockados, mas no futuro vai ter um apiResource aqui dentro
    customResource() {
      // Gera seed para geração de número aleatorio
      const cyrb128 = str => {
        let h1 = 1779033703,
          h2 = 3144134277,
          h3 = 1013904242,
          h4 = 2773480762;
        for (let i = 0, k; i < str.length; i++) {
          k = str.charCodeAt(i);
          h1 = h2 ^ Math.imul(h1 ^ k, 597399067);
          h2 = h3 ^ Math.imul(h2 ^ k, 2869860233);
          h3 = h4 ^ Math.imul(h3 ^ k, 951274213);
          h4 = h1 ^ Math.imul(h4 ^ k, 2716044179);
        }
        h1 = Math.imul(h3 ^ (h1 >>> 18), 597399067);
        h2 = Math.imul(h4 ^ (h2 >>> 22), 2869860233);
        h3 = Math.imul(h1 ^ (h3 >>> 17), 951274213);
        h4 = Math.imul(h2 ^ (h4 >>> 19), 2716044179);
        (h1 ^= h2 ^ h3 ^ h4), (h2 ^= h1), (h3 ^= h1), (h4 ^= h1);
        return [h1 >>> 0, h2 >>> 0, h3 >>> 0, h4 >>> 0];
      };

      // Retorna método que gera números aleatórios e aceita quatro seeds de parametro
      const sfc32 = (a, b, c, d) => {
        return function() {
          a |= 0;
          b |= 0;
          c |= 0;
          d |= 0;
          let t = (((a + b) | 0) + d) | 0;
          d = (d + 1) | 0;
          a = b ^ (b >>> 9);
          b = (c + (c << 3)) | 0;
          c = (c << 21) | (c >>> 11);
          c = (c + t) | 0;
          return (t >>> 0) / 4294967296;
        };
      };

      // Gera dados aleatórios usando o ano base como seed
      // Assim filtrar pelo mesmo ano base irá gerar os mesmos dados "aleatórios" sempre
      const genarateData = (year, isQuarter = false) => {
        const seeds = cyrb128(year);
        const rand = sfc32(...seeds);

        const response = new Array(isQuarter ? 4 : 12)
          .fill(null)
          .map((_, index) => {
            const lucroContabil = Math.floor(rand() * 10000000) / 10000;
            const totalAdicoes = Math.floor(rand() * 10000000) / 10000;
            const totalExclusoes = Math.floor(rand() * 10000000) / 10000;
            const lucroTotal = lucroContabil + totalAdicoes - totalExclusoes;
            const exclusaoLeiDoBem = Math.floor(rand() * 10000000) / 10000;
            const irpj = Math.floor(rand() * 10000000) / 10000;
            const csll = Math.floor(rand() * 10000000) / 10000;

            const data = {
              id: index,
              lucro_contabil: lucroContabil,
              total_adicoes: totalAdicoes,
              total_exclusoes: totalExclusoes,
              lucro_total: lucroTotal,
              exclusao_lei_bem: exclusaoLeiDoBem,
              irpj,
              csll
            };

            if (isQuarter) {
              data.trimestre = `${index + 1}`;
            } else {
              const month = `${index + 1}`.padStart(2, "0");
              data.competencia = `${year}-${month}`;
            }

            return data;
          });

        // Exclui alguns registros aleatórios para não ficar uma tabela perfeira
        new Array(isQuarter ? 1 : 3).fill(null).forEach(() => {
          const i = Math.floor(rand() * response.length - 1);
          response.splice(i, 1);
        });

        return response;
      };

      const self = this;

      return {
        async get({ query }) {
          try {
            // TODO aqui está o mock e é onde devo inserir a chamada de API real
            // const response = await this.apiResource('');
            const year = query.slice(-4);
            const isQuarter = toInteger(year) % 2 === 1;
            const itens = genarateData(year, isQuarter);
            const response = { trimestral: isQuarter, itens };

            self.isQuarter = !!response.trimestral;
            self.periods = response.itens
              .reduce((acc, { competencia, trimestre }) => {
                if (self.isQuarter) {
                  const quarter = `${trimestre}tri`;
                  return acc.includes(quarter) ? acc : [...acc, quarter];
                }

                return acc.includes(competencia) ? acc : [...acc, competencia];
              }, [])
              .sort();
            const rows = response.itens.reduce(
              (acc, curr) => {
                const { competencia, trimestre, ...rest } = curr;
                const col = self.isQuarter ? `${trimestre}tri` : competencia;
                Object.entries(rest).forEach(([key, value]) => {
                  if (key in acc) {
                    acc[key][col] = value;
                  }
                });
                return acc;
              },
              {
                lucro_contabil: { label: "Lucro contábil (antes IRPJ/CSLL)" },
                total_adicoes: { label: "Total de adições" },
                total_exclusoes: { label: "Total de exclusões" },
                lucro_total: {
                  label: "Lucro após adições e exclusões",
                  isTotal: true
                },
                exclusao_lei_bem: { label: "Exclusão lei do bem" },
                irpj: {
                  label: "Economia tributária — IRPJ (15% + adicional 10%)"
                },
                csll: { label: "Economia Tributária — CSLL (XXX%)" }
              }
            );

            return Promise.resolve(Object.values(rows));
          } catch (error) {
            self.isQuarter = false;
            self.periods = [];
            return Promise.resolve([]);
          }
        },
        save(...args) {
          console.log(args);
          return Promise.resolve({});
        },
        delete(...args) {
          console.log(args);
          return Promise.resolve({});
        }
      };
    },
    fields() {
      return [
        {
          key: "lucro_contabil",
          name: "Lucro contábil (antes IRPJ/CSLL)",
          type: this.$fieldTypes.MONEY,
          colSize: 12,
          rules: [{ rule: "required " }]
        },
        {
          key: "total_adicoes",
          name: "Total de adições",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
          rules: [{ rule: "required " }]
        },
        {
          key: "total_exclusoes",
          name: "Total exclusões",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
          rules: [{ rule: "required " }]
        },
        {
          key: "exclusao_lei_bem",
          name: "Exclusão lei do bem",
          type: this.$fieldTypes.MONEY,
          colSize: 6,
          rules: [{ rule: "required " }]
        },
        {
          key: "competencia",
          name: "Competência",
          type: this.$fieldTypes.DATE,
          colSize: 6,
          rules: [{ rule: "required " }]
        }
      ];
    }
  },
  data: function() {
    return {
      createModalData: {},
      createModalError: "",
      createModalOpened: false,
      isQuarter: false,
      periods: [],
      opts: {}
    };
  },
  methods: {
    async doSave(data, closeFn) {
      try {
        this.createModalError = "";
        // const response = await this.apiResource();
        await this.customResource.save(data);

        if (closeFn) {
          closeFn();
        }
      } catch (error) {
        this.createModalError = this.errorHandler(error);
      }
    }
  }
};
</script>
